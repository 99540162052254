import React, { useState, useEffect } from "react";

import useTopScroll from "../hooks/useTopScroll";
import ContainerCompany from "../APIs/ContainerCompany";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import CompanyCard from "../components/CompanyCard";
import "../styles/ContainerSelection.css";

import AppLoader from "../components/AppLoader";

export default function ContainerSelectionScreen() {
  const [companies, setCompanies] = useState([]);

  const fetchCompanies = async () => {
    try {
      const res = await ContainerCompany.get("/allActive");
      if (res.status === 200) {
        setCompanies(res.data.companies);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useTopScroll();

  const renderCards = () => {
    return companies.map((company) => {
      const buffer = Buffer.from(company.logo);
      const string64 = buffer.toString("base64");
      return (
        <CompanyCard
          to={`/container/info/${company._id}`}
          logo={`data:image/png;base64,${string64}`}
          comapanyName={company.name}
          compnayDisc={company.description}
        />
      );
    });
  };

  return (
    <div>
      <AppNavBar />
      <div className="select-container-main">
        <div className="sc-heading">Select Your Company</div>
        <div className="sc-card-main">
          {/* <CompanyCard
            to={"/container/info/" + "1"}
            logo={containerLogo1}
            comapanyName="Container Solutions L.L.C"
            compnayDisc="Leading Container Solutions and service provider in the
            Middle East, South and Central Asia."
          />
          <CompanyCard to="/container/info/2" />
          <CompanyCard to="/container/info/3" /> */}
          {companies.length > 0 ? renderCards() : <AppLoader />}
        </div>
      </div>
      <AppFooter />
    </div>
  );
}
