import React from "react";

export default function BackCircle({ className = "", ...styles }) {
  const circleStyles = {
    borderRadius: "50%",
    position: "absolute",
    ...styles.style,
  };
  return <div className={className} style={circleStyles}></div>;
}
