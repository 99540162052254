// import { config } from "firebase-functions";
export default {
  SPACE_URL: "https://tranquil-crag-34887.herokuapp.com/spaceBooking", // config().backend.space_url,
  SCREEN_URL: "https://tranquil-crag-34887.herokuapp.com/screenBooking", // config().backend.screen_url,
  COMPANY_URL: "https://tranquil-crag-34887.herokuapp.com/containerCompany", // config().backend.company_url,
  LEAD_URL: "https://tranquil-crag-34887.herokuapp.com/lead", // config().backend.lead_url,
  CONTRACT_URL: "https://tranquil-crag-34887.herokuapp.com/contract", // config().backend.contract_url,
  THAWANI_URL: "https://checkout.thawani.om",
  THAWANI_PUBLISHABLE_KEY: "IzfYZcGbv8HzkxOyJzOb5ulLy364SP",
  THAWANI_SECRET_KEY: "dN5r2agxEakJfxfR6Vlw81hJjLvk0G",
};
