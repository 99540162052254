import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import "./styles/Home.css";

import history from "./history";

import HomeScreen from "./screens/HomeScreen.js";
import EventCalenderScreen from "./screens/EventCalenderScreen";
import ScreenCalenderScreen from "./screens/ScreenCalenderScreen";
import ContainerInfoScreen from "./screens/ContainerInfoScreen";
import ScreenInfoScreen from "./screens/ScreenInfoScreen";
import EventInfoScreen from "./screens/EventInfoScreen";
import ContainerSelectionScreen from "./screens/ContainerSelectionScreen.js";
import EventContractDownloadPageScreen from "./screens/EventContractDownloadPageScreen";
import ScreenContractDownloadPageScreen from "./screens/ScreenContractDownloadPageScreen";
import ScreenContractForm from "./screens/ScreenContractForm";
import EventContractUploadScreen from "./screens/EventContractUploadScreen";
import ScreenContractUploadScreen from "./screens/ScreenContractUploadScreen";
import ScreenPaymentForm from "./screens/ScreenPaymentForm";
import ScreenBookingConfirmationScreen from "./screens/ScreenBookingConfirmationScreen";
import EventBookingConfirmationScreen from "./screens/EventBookingConfirmationScreen";
import ContainerBookingConfirmationScreen from "./screens/ContainerBookingConfirmationScreen";
import EventContractForm from "./screens/EventContractForm";
import EventPaymentForm from "./screens/EventPaymentForm";
import ContainerContactForm from "./screens/ContainerContactForm";
import ContainerQuestions from "./screens/ContainerQuestions";
import ComingSoon from "./screens/ComingSoon";

import ReactGA from "react-ga";

export default function App() {
  useEffect(() => {
    ReactGA.initialize("UA-199825139-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (localStorage.getItem("eventContractDownloaded")) {
      history.push("/event/contractUpload");
    } else if (localStorage.getItem("screenContractDownloaded")) {
      history.push("/screen/contractUpload");
    }
  }, []);
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/event/info" exact component={EventInfoScreen} />
          <Route
            path="/event/bookingDates"
            exact
            component={EventCalenderScreen}
          />
          <Route path="/event/contract" exact component={EventContractForm} />
          <Route
            path="/event/contractDownload"
            exact
            component={EventContractDownloadPageScreen}
          />
          <Route
            path="/event/contractUpload"
            exact
            component={EventContractUploadScreen}
          />
          <Route path="/event/payment" exact component={EventPaymentForm} />
          <Route
            path="/event/confirmation"
            exact
            component={EventBookingConfirmationScreen}
          />

          <Route path="/screen/info" exact component={ScreenInfoScreen} />
          <Route
            path="/screen/bookingDates"
            exact
            component={ScreenCalenderScreen}
          />
          <Route path="/screen/contract" exact component={ScreenContractForm} />
          <Route
            path="/screen/contractDownload"
            exact
            component={ScreenContractDownloadPageScreen}
          />
          <Route
            path="/screen/contractUpload"
            exact
            component={ScreenContractUploadScreen}
          />
          <Route path="/screen/payment" exact component={ScreenPaymentForm} />
          <Route
            path="/screen/confirmation"
            exact
            component={ScreenBookingConfirmationScreen}
          />

          <Route
            path="/container/select"
            exact
            component={ContainerSelectionScreen}
          />
          <Route
            path="/container/info/:companyId"
            exact
            component={ContainerInfoScreen}
          />
          <Route
            path="/container/contact/:companyId/:companyName"
            exact
            component={ContainerContactForm}
          />
          <Route
            path="/container/questions/:companyId/:companyName"
            exact
            component={ContainerQuestions}
          />
          <Route
            path="/container/confirmation"
            exact
            component={ContainerBookingConfirmationScreen}
          />
          <Route path="/upcoming" exact component={ComingSoon} />
          {/* <Route
            path="/container/payment"
            exact
            component={ContainerPaymentForm}
          /> */}
        </Switch>
      </div>
    </Router>
  );
}
