import React from "react";
import "../../styles/ContractUpload.css";

import AppButton from "../AppButton";
import AppTimer from "../AppTimer";
import AppTitle from "../AppTitle";
import AppInfoImages from "../AppInfoImages";
import AppInfoHeading from "../AppInfoHeading";

export default function CompanyUploadSection({
  mainHeading,
  rightButton,
  // to = "",
  contract = { name: "", file: "" },
  contractChangeHandler,
  video = { name: "", file: "" },
  videoChangeHandler,
  onNext,
  uploadType = "single",
  bannerSrc = "",
  remainingTime = "04:59",
}) {
  return (
    <div className="upload-container">
      <AppInfoImages source={bannerSrc} />
      <AppInfoHeading mainHeading={mainHeading} />
      <div className="upload-details-section">
        <AppTimer timeRemaining={remainingTime} />
        {uploadType === "double" ? (
          <div style={{ marginBottom: "100px" }}>
            <AppTitle
              title="Upload your advertisment video by clicking below (max 20 MB) "
              styles={{
                color: "white",
                marginTop: "20px",
                marginBottom: "20px",
                fontWeight: "700",
              }}
            />

            <div className="upload-left-details-buttons">
              <div className="upload-file-input">
                <input
                  type="file"
                  name="video"
                  accept=".mp4"
                  value={video.name}
                  onChange={videoChangeHandler}
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <AppTitle
          title="Upload contract  by clicking below "
          styles={{
            color: "white",
            marginTop: "20px",
            marginBottom: "20px",
            fontWeight: "700",
          }}
        />

        <div className="upload-left-details-buttons">
          <div className="upload-file-input">
            <input
              type="file"
              name="contract"
              accept=".pdf"
              value={contract.name}
              onChange={contractChangeHandler}
            />
          </div>
          <AppButton
            title={rightButton}
            bgColor="black"
            styles={{
              height: "40px",
              width: "140px",
              border: "1px solid #ED392D",
              fontSize: "12px",
              marginTop: "4.5vh",
            }}
            onButtonClick={onNext}
            // to={to}
          />
        </div>
      </div>
    </div>
  );
}
