import React from "react";
import { Element } from "react-scroll";

import AppButton from "../AppButton";
import { section3 as offerImage } from "../../assets/outsourceAssets";
import AppDetail from "../AppDetail";
import AppTitle from "../AppTitle";
import AppImageVideo from "../AppImageVideo";
import AppLists from "../AppLists";
import OfferImageCircles from "../HomeCircles/OfferImageCircles";

const allItems = [
  "Screens are customized to be fitted in a 40ft Container.",
  "The Viewing angle is 150-120 Degree.",
  "The screens are designed to demand viewer’s attention",
];

function OfferSection3() {
  return (
    <Element name="section-3">
      <div className="right-img hero-section section-3">
        <div className="hero-left">
          <AppTitle
            title="Book a Screen’s space"
            fontWeight={700}
            lineHeight="45.16px"
          />
          <AppDetail detail="We offer advertisement screens in an area surrounded by colleges, residential & commercial area in the heart of Basuher." />
          <AppLists allItems={allItems} />
          <AppButton
            title="Book Now"
            to="/screen/info"
            GAEvent={{
              title: "Screen Booking button (Book Now)",
              description: `Button located at ${window.location.href}`,
            }}
          />
        </div>
        <div className="hero-right" style={{ position: "relative" }}>
          <OfferImageCircles />
          <AppImageVideo image={offerImage} />
        </div>
      </div>
    </Element>
  );
}
export default OfferSection3;
