import React, { useEffect, useState } from "react";

import useTopScroll from "../hooks/useTopScroll";
import ContainerCompany from "../APIs/ContainerCompany";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import CompanyInfoSection from "../components/Sections/CompanyInfoSection";
import history from "../history";

export default function CoontainerInfoScreen({ match }) {
  const [company, setCompany] = useState({});

  const fetchCompany = async () => {
    try {
      const res = await ContainerCompany.get(
        "/singleCompany?companyId=" + match.params.companyId
      );
      if (res.status === 200) {
        const buffer = Buffer.from(res.data.company.logo);
        const string64 = buffer.toString("base64");
        const companyToSet = {
          _id: res.data.company._id,
          companyDetail: res.data.company.details,
          companyName: res.data.company.name,
          companyLogo: `data:image/png;base64,${string64}`,
          servicesList: res.data.company.services,
        };
        setCompany(companyToSet);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  useTopScroll();

  const clickHandler = () => {
    history.push(
      "/container/contact/" + match.params.companyId + "/" + company.companyName
    );
  };

  return (
    <div>
      <AppNavBar />
      <CompanyInfoSection {...company} onCustomizeContainer={clickHandler} />
      <AppFooter />
    </div>
  );
}
