import React, { useEffect, useState } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import InputSelectionPage from "../components/Sections/InputSelectionPage";
import ContainerCompany from "../APIs/ContainerCompany";
import AppLoader from "../components/AppLoader";
import history from "../history";
import Lead from "../APIs/Lead";

export default function ContainerPage1({ match }) {
  const [questions, setQuestions] = useState([]);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectionImgSrc, setSelectionImgScr] = useState("");

  useTopScroll();

  const fetchQuestions = async () => {
    try {
      const res = await ContainerCompany.get(
        "/allQuestions?companyId=" + match.params.companyId
      );
      if (res.status === 202) {
        const answersToSet = res.data.questions.map((question) => {
          return {
            question: question.question,
            questionType: question.questionType,
            answer: "",
          };
        });
        if (res.data.questions.length === 0) {
          localStorage.removeItem("leadBookingId");
          history.push("/container/confirmation");
        }
        setQuestions(res.data.questions);
        setAnswers(answersToSet);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("leadBookingId"))
      history.push("/container/select");
    fetchQuestions();
  }, []);

  const handleInput = (value) => {
    answers[questionNumber].answer = value;
  };

  const handleSelection = (value, index) => {
    answers[questionNumber].answer = value;
    if (index === 0) {
      const buffer = Buffer.from(questions[questionNumber].attachment);
      const string64 = buffer.toString("base64");
      setSelectionImgScr(`data:image/png;base64,${string64}`);
    }
    if (index > 0) {
      const buffer = Buffer.from(
        questions[questionNumber].multipleChoice[index - 1].choiceAttachment
      );
      const string64 = buffer.toString("base64");
      setSelectionImgScr(`data:image/png;base64,${string64}`);
    }
  };

  const handleLeftButtonCLick = async (type) => {
    if (answers[questionNumber].answer === "")
      return type === "single"
        ? alert("Please Answer the Question")
        : alert("Please Select an Option");
    if (questionNumber === questions.length - 1) {
      // localStorage.removeItem("leadBookingId");
      history.push("/container/confirmation");
    }

    await Lead.patch("/update", {
      _id: localStorage.getItem("leadBookingId"),
      questionAnswers: answers,
    });

    setQuestionNumber(questionNumber + 1);
    if (document.getElementsByClassName("input-field")[0])
      document.getElementsByClassName("input-field")[0].value = "";
    if (document.getElementsByClassName("select-field")[0])
      document.getElementsByClassName("select-field")[0].value = "";
  };

  const setStartImage = () => {
    const buffer = Buffer.from(questions[questionNumber].attachment);
    const string64 = buffer.toString("base64");
    return `data:image/png;base64,${string64}`;
  };

  const renderInputs = () => {
    if (questions[questionNumber].questionType === "single") {
      const buffer = Buffer.from(questions[questionNumber].attachment);
      const string64 = buffer.toString("base64");
      return (
        <InputSelectionPage
          mainHeading={match.params.companyName}
          subHeading={
            questionNumber + 1 + ": " + questions[questionNumber].question
          }
          inputPlaceholder={questions[questionNumber].question}
          leftButton="Next"
          leftButtonClickHandler={() => {
            handleLeftButtonCLick(questions[questionNumber].questionType);
            // if (answers[questionNumber].answer === "")
            //   return alert("Please Answer the Question");
            // if (questionNumber === questions.length - 1)
            //   history.push("/container/confirmation");
            // setQuestionNumber(questionNumber + 1);
            // document.getElementsByClassName("input-field")[0].value = "";
          }}
          rightButton={questionNumber > 0 ? "Back" : ""}
          rightButtonClickHandler={() => {
            setQuestionNumber(questionNumber - 1);
            document.getElementsByClassName("input-field")[0].value = "";
          }}
          imgSrc={`data:image/png;base64,${string64}`}
          inputEntered={handleInput}
        />
      );
    } else {
      const selectOptions = questions[questionNumber].multipleChoice.map(
        (choice) => {
          return choice.choice;
        }
      );

      return (
        <InputSelectionPage
          mainHeading={match.params.companyName}
          subHeading={
            questionNumber + 1 + ": " + questions[questionNumber].question
          }
          defaultSelect={questions[questionNumber].question}
          selectOptions={selectOptions}
          leftButton="next"
          leftButtonClickHandler={() => {
            handleLeftButtonCLick(questions[questionNumber].questionType);
            // if (answers[questionNumber].answer === "")
            //   return alert("Please Select an Option");
            // if (questionNumber === questions.length - 1)
            //   history.push("/container/confirmation");
            // setQuestionNumber(questionNumber + 1);
            // document.getElementsByClassName("select-field")[0].value = "";
          }}
          rightButton={questionNumber > 0 ? "Back" : ""}
          rightButtonClickHandler={() => {
            setQuestionNumber(questionNumber - 1);
            document.getElementsByClassName("select-field")[0].value = "";
          }}
          optionSelected={(value, index) => handleSelection(value, index)}
          imgSrc={selectionImgSrc || setStartImage()}
        />
      );
    }
  };

  return (
    <div>
      <AppNavBar />
      {questions.length > 0 && answers.length > 0 ? (
        renderInputs()
      ) : (
        <div className="selectPage-container">
          <AppLoader />
        </div>
      )}
      <AppFooter />
    </div>
  );
}
