import React from "react";

export default function AppInput({
  type = "text",
  label = "",
  placeholder = "placeholder",
  styles = {},
  inputValue = "",
  onInputChange,
  error = null,
}) {
  return (
    <div style={{ margin: "0 0 15px 0" }}>
      <div style={{ fontSize: "12px", lineHeight: "25px", color: "#FFF" }}>
        {label}
      </div>
      <input
        name="name"
        type={type}
        placeholder={placeholder}
        value={inputValue}
        onChange={onInputChange}
        style={{
          border: "0.891525px solid #CEC8FF",
          borderRadius: "4.5px",
          fontSize: "16px",
          padding: "5px",
          height: "43px",
          width: "263px",
          color: "#827e7e",
          ...styles,
        }}
      />
      {error ? (
        <div style={{ fontSize: "14px", color: "#ed392d" }}>{error}</div>
      ) : null}
    </div>
  );
}
