import React from "react";
import { Element } from "react-scroll";

import AppButton from "../AppButton";
import { eventSpaceImg as offerImage } from "../../assets/outsourceAssets";
import AppDetail from "../AppDetail";
import AppTitle from "../AppTitle";
import AppImageVideo from "../AppImageVideo";
import AppLists from "../AppLists";
import OfferImageCircles from "../HomeCircles/OfferImageCircles";

const allItems = [
  "9 restaurants commanding their own footfall",
  "Thy Yard has a strategic location.",
  "New Concept in Oman",
];

function OfferSection2() {
  return (
    <Element className="left-img hero-section section-2" name="section-2">
      {" "}
      {/* <div className="left-img hero-section section-2"> */}{" "}
      <div className="hero-left" style={{ position: "relative" }}>
        <OfferImageCircles />
        <AppImageVideo image={offerImage} />{" "}
      </div>{" "}
      <div className="hero-right">
        <AppTitle
          title="Book your space for events and more"
          fontWeight={700}
          lineHeight="35.16px"
        />
        <AppDetail detail="Thy Yard community gives the opportunity to book your space in case of events and more. We are situated at a strategic location where you will never fall short of an audience." />
        <AppLists allItems={allItems} />{" "}
        <AppButton
          title="Book Now"
          to="/event/info"
          GAEvent={{
            title: " Event Space Booking button (Book Now)",
            description: `Button located at ${window.location.href}`,
          }}
        />{" "}
      </div>{" "}
      {/* </div> */}{" "}
    </Element>
  );
}
export default OfferSection2;
