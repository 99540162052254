import React from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import InfoSection from "../components/Sections/InfoSection";
import { eventBanner } from "../assets/outsourceAssets";
import { eventSpaceImg as eventBannerMobile } from "../assets/outsourceAssets";
import ContractApi from "../APIs/Contract";

export default function CompanyInfoScreen() {
  useTopScroll();

  const [infoBtn, setInfoBtn] = React.useState("More Information");

  const renderBanner = () => {
    if (window.innerWidth < 786) return eventBannerMobile;
    return eventBanner;
  };

  const MoreInfoDowload = async () => {
    try {
      setInfoBtn("Downloading...");
      const res = await ContractApi.get("/singleContract?fileType=spaceInfo");
      if (res.data.file) {
        const arr = new Uint8Array(res.data.file.file.data);
        const blob = new Blob([arr], { type: "application/pdf" });
        try {
          var url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          var a = document.createElement("a");
          a.setAttribute("download", "eventMoreInfo.pdf");
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } catch (e) {
          setInfoBtn("More Information");
        }
      } else {
        alert("Oops, An Error occured while downloading Info!");
      }
      setInfoBtn("More Information");
    } catch (e) {
      setInfoBtn("More Information");
    }
  };

  return (
    <div>
      <AppNavBar />
      <InfoSection
        mainHeading={"Event Booking"}
        leftDetails={
          "Thy Yard community gives the opportunity to book your space in case of events and more. We are situated at a strategic location where you will never fall short of an audience."
        }
        leftButton={"Book Now"}
        rightButton={infoBtn}
        rightHeading={"Benefits of booking our space:"}
        rightAllItems={[
          "9 resturants commanding their own footfall.",
          "Thy Yard has a strategic location.",
          "New Concept in Oman.",
        ]}
        to="/event/bookingDates"
        bannerSrc={renderBanner()}
        leftBtnGAEvent={{
          title: "Event Space Start Booking Button (Book Now)",
          description: `Button located at ${window.location.href}`,
        }}
        onRightClick={MoreInfoDowload}
      />
      <AppFooter />
    </div>
  );
}
