import React from "react";
import { Element } from "react-scroll";

import AppButton from "../AppButton";
import { section4 as offerImage } from "../../assets/outsourceAssets";
import AppDetail from "../AppDetail";
import AppTitle from "../AppTitle";
import AppImageVideo from "../AppImageVideo";
import AppLists from "../AppLists";
import OfferImageCircles from "../HomeCircles/OfferImageCircles";

const allItems = [
  "Easy to customize and have a creative look",
  "Sustainable material",
  "Can be used and fabricated to anything",
];

function OfferSection4() {
  return (
    <Element name="section-4">
      <div className="left-img hero-section section-4">
        <div className="hero-left" style={{ position: "relative" }}>
          <OfferImageCircles />
          <AppImageVideo image={offerImage} />
        </div>
        <div className="hero-right">
          <AppTitle
            title="Buy & Customize your own Container "
            fontWeight={700}
            lineHeight="35.16px"
          />
          <AppDetail detail="Want to own your own container but dont know how? Buy now through Thy Yard Kit with an easy friendly process and confirm your order within minutes. " />
          <AppLists allItems={allItems} />
          <AppButton
            title="Book Now"
            to="/container/select"
            GAEvent={{
              title: "Buy Container Book Now Btn",
              description:
                "Button on Home Screen to navigate towards container/select",
            }}
          />
        </div>
      </div>
    </Element>
  );
}
export default OfferSection4;
