import React from "react";
import Modal from "react-modal";
import "../styles/AppModal.css";

export default function AppModal({ isOpen = false, closeHandler = null }) {
  return (
    <Modal isOpen={isOpen} className="modal-content">
      <div>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Call Us
          </h5>
          <div class="btn-close" onClick={closeHandler}>
            X
          </div>
        </div>
        <div class="modal-body">
          You can call us at <strong>0096879195911</strong> 24/7
        </div>
      </div>
    </Modal>
  );
}
