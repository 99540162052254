import React from "react";
import AppButton from "./AppButton";

export default function CompanyCard({
  to = "",
  logo = "",
  comapanyName = "Company Name",
  compnayDisc = "Company Description",
}) {
  return (
    <div className="sc-card">
      {/* <div className="sc-logo">{logo}</div> */}
      <div className="sc-logo">
        <img src={logo} className="sc-logo-inside" />
      </div>
      <div className="sc-company-name">{comapanyName}</div>
      <div className="sc-description">
        {/* Slate helps you see how many more days you need to work to reach */}
        {compnayDisc}
      </div>
      <div className="sc-button">
        <AppButton
          title="Customize Now"
          to={to}
          GAEvent={{
            title: "Container Customize Btn",
            description: `Button of ${comapanyName} company on container/select Screen to navigate towards ${to}`,
          }}
        />
      </div>
    </div>
  );
}
