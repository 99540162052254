export const connectUsLogo =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FconnectUsLogo.png?alt=media&token=41b6c083-ab93-4a44-baa6-e17f43afcbb0";

export const containerBanner =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FcontainerBanner.jpg?alt=media&token=8c4968cf-33d9-44fb-8589-ffd0dbacbe50";

export const containerImg =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FcontainerImg.jpg?alt=media&token=d69f506e-5edf-4979-b5ab-52a213d2bc0b";

export const eventBanner =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fevent_banner.png?alt=media&token=2dd7161a-5d77-4c4d-84ab-86ea06df5811";

export const eventSpaceImg =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FeventSpaceImg.jpg?alt=media&token=ef301140-c785-43ec-945a-72628cc715e3";

export const heroVideo =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FheroVideo.mp4?alt=media&token=adb60ec7-3270-4090-b265-ed6bc7f6205d";

export const logo =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fhome_nav_icon.png?alt=media&token=38f7312e-4b17-448c-9a71-94796df5c9b4";

export const screenBanner =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fscreen_banner.png?alt=media&token=b76e54de-8c7d-4a07-a6b9-ab47fb8abce6";

export const ScreenImg =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FScreenImg.jpg?alt=media&token=45ef3f73-8096-4d92-93d1-951db88800f3";

export const offerImage =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fsection-1.png?alt=media&token=46a7e9e1-b91b-4f5e-9d21-1b8543d37071";

export const section3 =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fsection-3.jpg?alt=media&token=9a2c2823-2525-44fe-938c-f7896eb6d991";

export const section4 =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fsection-4.jpg?alt=media&token=91a17729-1d85-454a-8f51-e598243168a9";

export const selectContainer =
  "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2Fselect-container.jpg?alt=media&token=102749ef-4d5d-40ae-a7a3-7929c2dc86e0";

// export const contract =
//     "https://firebasestorage.googleapis.com/v0/b/thyyard-webapp.appspot.com/o/outsourceAssets%2FtestContract.pdf?alt=media&token=6bf2d0ca-3867-40da-80f8-54fdd22634ef";
