import React from "react";

export default function AppImageVideo({ video, image, ...otherStyles }) {
  return (
    <>
      {video ? (
        <div className="hero-video">
          <video width="100%" height="100%" autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div
          className="offer-image-container"
          style={{
            overflow: "hidden",
            left: "90.76%",
            right: "-31.53%",
            boxShadow: "-5px 5px 11px rgba(0, 0, 0, 0.1)",
            zIndex: 2,
          }}
        >
          <img
            className="offer-image"
            src={image}
            style={{
              maxHeight: "700px",
              height: "inherit",
              width: "45vw",
              maxWidth: "585px",
              objectFit: "contain",
            }}
            alt=""
          />
        </div>
      )}
    </>
  );
}
