import React from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import InfoSection from "../components/Sections/InfoSection";
import { screenBanner } from "../assets/outsourceAssets";
import { ScreenImg as screenBannerMobile } from "../assets/outsourceAssets";
import ContractApi from "../APIs/Contract";

export default function CompanyInfoScreen() {
  useTopScroll();
  const [infoBtn, setInfoBtn] = React.useState("Screen Specs");
  const renderBanner = () => {
    if (window.innerWidth < 786) return screenBannerMobile;
    return screenBanner;
  };
  const MoreInfoDowload = async () => {
    try {
      setInfoBtn("Downloading...");
      const res = await ContractApi.get("/singleContract?fileType=screenInfo");
      if (res.data.file) {
        const arr = new Uint8Array(res.data.file.file.data);
        const blob = new Blob([arr], { type: "application/pdf" });
        try {
          var url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          var a = document.createElement("a");
          a.setAttribute("download", "screenMoreInfo.pdf");
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } catch (e) {
          setInfoBtn("Screen Specs");
        }
      } else {
        alert("Oops, An Error occured while downloading Specs!");
      }
      setInfoBtn("Screen Specs");
    } catch (e) {
      setInfoBtn("Screen Specs");
    }
  };
  return (
    <div>
      <AppNavBar />
      <InfoSection
        mainHeading={"Thy Yard's Screen"}
        leftDetails={
          "Thy Yard Advertisement Screens are implemented in a unique concept called Thy Yard, which is located in a strategic location surrounded by colleges, residential & commercial area in the heart of Basuher."
        }
        leftButton={"Book Now"}
        rightButton={infoBtn}
        rightHeading={"Benefits of using our screens:"}
        rightAllItems={[
          "For the first time in Oman the screens are customized to be fitted in a 40ft Container.",
          "The Viewing angle is 150-120 Degree.",
          "The screens are designed to demand attention; which will increase brand awareness and visibility, and target relevant audiences with retargeting campaigns.",
        ]}
        to="/screen/bookingDates"
        bannerSrc={renderBanner()}
        leftBtnGAEvent={{
          title: "Screen Start Booking Button (Book Now)",
          description: `Button located at ${window.location.href}`,
        }}
        onRightClick={MoreInfoDowload}
      />
      <AppFooter />
    </div>
  );
}
