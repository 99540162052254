import React, { useState } from "react";
import { Link } from "react-router-dom";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import AppInput from "../components/AppInput";
import ScreenBooking from "../APIs/ScreenBooking";
import Thawani from "../APIs/Thawani";
import config from "../config/key";
import history from "../history";
import paymentImg from "../assets/payment-bg.png";
import "../styles/PaymentForm.css";
import ReactGA from "react-ga";

export default function ScreenPayment() {
  useTopScroll();
  const [amount, setAmount] = useState(100000);

  const onAmountPay = async () => {
    try {
      const { name, email, contact } = JSON.parse(
        localStorage.getItem("screenUserInfo")
      );

      const sessionBody = {
        client_reference_id: localStorage.getItem("screenBookingId"),
        products: [
          {
            name: "Screen",
            unit_amount: amount,
            quantity: 1,
          },
        ],
        success_url: window.location.href,
        cancel_url: window.location.href,
        metadata: {
          customer_name: name,
          customer_email: email,
          customer_contact: contact,
        },
      };

      const res = await Thawani.post("/api/v1/checkout/session", sessionBody, {
        headers: {
          "thawani-api-key": config.THAWANI_SECRET_KEY,
        },
      });

      const res2 = await ScreenBooking.patch("/updateSessionId", {
        _id: localStorage.getItem("screenBookingId"),
        sessionId: res.data.data.session_id,
      });

      if (res2.status !== 202) {
        return alert("Oops! an error occured while doing payment session");
      }

      window.open(
        `${config.THAWANI_URL}/pay/${res.data.data.session_id}?key=${config.THAWANI_PUBLISHABLE_KEY}`,
        "_blank"
      );
      await ReactGA.event({
        category: "Screen thawani payment Button (Pay Now)",
        action: `Button located at ${window.location.href}`,
      });
      history.push("/screen/confirmation");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="payment-container">
      <AppNavBar />
      <div className="payment-main">
        <div className="payment-left">
          <div
            style={{
              fontFamily: "Poppins",
            }}
          >
            <div className="payment-heading">Payment for Screen Booking</div>
            <div className="payment-detail">
              <p>Thank you for choosing Thy Yard’s screen to run your ads.</p>
              <p>
                In order to book a consultation call to discuss your event
                further, please pay {amount / 1000} OMR as a refundable security
                deposit. By clicking the 'Pay Now' button below, it will lead
                you to a secure payment gateway to enter your credit/debit card
                information.
              </p>
              <p>
                After your payment, Thy Yard’s representative will contact you
                to further the process.
              </p>
            </div>
            <div className="payment-sub-heading">
              Please enter your card and contact details
            </div>
            {/* <div className="payment-inputs">
              <div className="payment-sub-inputs">
                <AppInput placeholder="JOHN SMITH" label="Name" />
                <AppInput
                  styles={{ width: "190px" }}
                  type="email"
                  placeholder="abc@abc.com"
                  label="Email"
                />
              </div>
              <div className="payment-sub-inputs">
                <AppInput
                  type="number"
                  placeholder="xxxx xxxx xxxx xxxx"
                  label="Card Number"
                />
                <AppInput
                  styles={{ width: "190px" }}
                  type="tel"
                  placeholder="+968 90126789"
                  label="Contact Number"
                />
              </div>
              <div
                className="payment-sub-inputs"
                style={{ flexDirection: "row", gap: "1rem" }}
              >
                <AppInput
                  styles={{ width: "68px" }}
                  type="number"
                  placeholder="MM"
                  label="Expiry Date"
                />
                <AppInput
                  styles={{ width: "68px" }}
                  type="number"
                  placeholder="YY"
                  label={"*"}
                />
                <AppInput
                  styles={{ width: "68px" }}
                  type="number"
                  placeholder="****"
                  label="CVV"
                />
              </div>
            </div> */}
            <div className="py-total-amount">
              Amount Payable:
              <span style={{ color: "rgba(237, 57, 45, 1)" }}>
                {amount / 1000} OMR
              </span>
            </div>
            <br />
            <div className="payment-btns">
              <div>
                <div
                  className="py-pay-now-btn"
                  style={{ cursor: "pointer" }}
                  onClick={onAmountPay}
                >
                  Pay Now
                </div>
              </div>
              <div>
                <Link
                  className="py-back-btn"
                  onClick={async () => {
                    await ReactGA.event({
                      category: "Screen go to Contract Upload Button (Back)",
                      action: `Button located at ${window.location.href}`,
                    });
                  }}
                  to="/screen/contractUpload"
                >
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="payment-right">
          <div>
            <img
              src={paymentImg}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
}
