import React from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import HeroSection from "../components/Sections/HeroSection";
import OfferSection1 from "../components/Sections/OfferSection1";
import OfferSection2 from "../components/Sections/OfferSection2";
import OfferSection3 from "../components/Sections/OfferSection3";
import OfferSection4 from "../components/Sections/OfferSection4";
import ContactUs from "../components/Sections/ConnectUs";
import AppFooter from "../components/Sections/AppFooter";

export default function Home() {
  useTopScroll();
  return (
    <div>
      <AppNavBar type="scroll" />
      <HeroSection />
      <OfferSection1 />
      <OfferSection2 />
      <OfferSection3 />
      <OfferSection4 />
      <ContactUs />
      <AppFooter />
    </div>
  );
}
