import React from "react";
import BackCircle from "../BackCircle";

export default function OfferImageCircles() {
  return (
    <>
      <BackCircle
        style={{
          backgroundColor: "#ED392D",
          height: "18px",
          width: "18px",
          left: "-57px",
          top: "55px",
          zIndex: 1,
        }}
        className="tiny-img-red-cicle"
      />
      <BackCircle
        style={{
          backgroundColor: "#F9AD1A",
          height: "8px",
          width: "8px",
          left: "-62px",
          top: "310px",
          zIndex: 1,
        }}
        className="tiny-img-orange-cicle"
      />
      <BackCircle
        style={{
          backgroundImage:
            "linear-gradient(315.81deg, #F9AD1A 8.18%, rgba(99, 93, 83, 0.21) 60.09%)",
          height: "94px",
          width: "94px",
          zIndex: 4,
        }}
        className="large-img-orange-cicle"
      />
      <BackCircle
        style={{
          backgroundImage:
            "linear-gradient(129.34deg, #ED392D 0.44%, rgba(237, 57, 45, 0) 63.64%)",
          height: "94px",
          width: "94px",
          bottom: "-10.3px",
          right: "-17px",
          zIndex: 4,
        }}
        className="large-img-red-cicle"
      />
    </>
  );
}
