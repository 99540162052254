import React from "react";

export default function AppImageLogo({ logo, link }) {
  return (
    <a
      style={{
        height: "40px",
        width: "40px",
      }}
      href={link}
      target="_blank"
    >
      <span style={{ color: "red", fontSize: "44px" }}>
        <i class={logo}></i>
      </span>
    </a>
  );
}
