import React, { useEffect, useState } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import ContractPageSection from "../components/Sections/ContractPageSection";
import { eventBanner } from "../assets/outsourceAssets";
import { eventSpaceImg as eventBannerMobile } from "../assets/outsourceAssets";
import ContractApi from "../APIs/Contract";
import history from "../history";

export default function CompanyInfoScreen() {
  const [contractDownloaded, setContractDownloaded] = useState(false);
  const [contractBtn, setContractBtn] = useState("Download Contract");

  useEffect(() => {
    if (!localStorage.getItem("eventBookingId"))
      history.push("/event/bookingDates");
  }, []);

  useTopScroll();

  const renderBanner = () => {
    if (window.innerWidth < 786) return eventBannerMobile;
    return eventBanner;
  };

  const onDownloadContract = async () => {
    try {
      setContractBtn("Downloading...");
      const res = await ContractApi.get(
        "/singleContract?fileType=spaceContract"
      );
      if (res.data.file) {
        const arr = new Uint8Array(res.data.file.file.data);
        const blob = new Blob([arr], { type: "application/pdf" });
        try {
          var url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          var a = document.createElement("a");
          a.setAttribute("download", "eventContract.pdf");
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setContractDownloaded(true);
        } catch (e) {
          setContractBtn("Download Contract");
        }
      } else {
        alert("Oops, An Error occured while downloading contract!");
      }
      setContractBtn("Download Contract");
    } catch (e) {
      setContractBtn("Download Contract");
    }
  };

  const onNext = () => {
    if (contractDownloaded) {
      localStorage.setItem("eventContractDownloaded", contractDownloaded);
      localStorage.setItem("eventBookingTime", new Date());
      history.push("/event/contractUpload");
    } else alert("Please Download Contract First");
  };

  return (
    <div>
      <AppNavBar />
      <ContractPageSection
        mainHeading={"The Contract"}
        listHeading={
          "We have booked your space for the next 5 hours. In order to complete the process, you would need to do the following:"
        }
        allItems={[
          "Download the contract.",
          "Fill the contract.",
          "Scan the contract through CamScan.",
          "Upload the complete contract in PDF file on the next page",
          "Pay a small refundable fee to book a call from Thy Yard’s representative to proceed with your booking.",
          "Please note that after 5 hours, you would need to check the dates again.",
        ]}
        leftButton={contractBtn}
        rightButton={"Next"}
        onLeftButtonClick={onDownloadContract}
        onRightButtonClick={onNext}
        bannerSrc={renderBanner()}
        // to="/event/contractUpload"
        downloadContractGAEvent={{
          title: "Event Space contract download Button (Download Contract)",
          description: `Button located at ${window.location.href}`,
        }}
        nextPageGAEvent={{
          title: "Event Space contract download confirmation Button (Next)",
          description: `Button located at ${window.location.href}`,
        }}
      />
      <AppFooter />
    </div>
  );
}
