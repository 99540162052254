import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as NavigationLink } from "react-router-dom";

import { logo } from "./../../assets/outsourceAssets";
import AppModal from "../AppModal";

export default function HomeNavBar({ type = "navigation" }) {
  const [callModal, setCallModal] = useState(false);
  const callHandler = () => {
    const ua = window.navigator.userAgent;
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      window.open("tel:0096879195911");
    } else {
      setCallModal(true);
    }
  };
  return (
    <>
      <AppModal
        isOpen={callModal}
        closeHandler={() => {
          setCallModal(false);
        }}
      />
      <div style={{ height: "6.5vh" }}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar abs-left-x nav-logo">
            <img alt="Thy Yard Logo" src={logo} />
          </div>
          {/* <div className="navbar-nav abs-center-x"> */}
          <div className="collapse navbar-collapse abs-center-x" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item active">
                {type !== "navigation" ? (
                  <ScrollLink
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    to="section-1"
                    duration={500}
                  >
                    <a className="btn btn-sm left-btn" href="#">
                      Order Now<span className="sr-only">(current)</span>
                    </a>
                  </ScrollLink>
                ) : (
                  <NavigationLink to="/">
                    <a className="btn btn-sm left-btn" href="#">
                      Home<span className="sr-only">(current)</span>
                    </a>
                  </NavigationLink>
                )}
              </li>
              <li className="nav-item">
                {type !== "navigation" ? (
                  <ScrollLink
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    to="section-2"
                    duration={500}
                  >
                    <a className="btn btn-sm left-btn" href="#">
                      Events
                    </a>
                  </ScrollLink>
                ) : (
                  <NavigationLink to="/event/info">
                    <a className="btn btn-sm left-btn" href="#">
                      Events
                    </a>
                  </NavigationLink>
                )}
              </li>
              <li className="nav-item">
                {type !== "navigation" ? (
                  <ScrollLink
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    to="section-3"
                    duration={500}
                  >
                    <a className="btn btn-sm left-btn" href="#">
                      Screens
                    </a>
                  </ScrollLink>
                ) : (
                  <NavigationLink to="/screen/info">
                    <a className="btn btn-sm left-btn" href="#">
                      Screens
                    </a>
                  </NavigationLink>
                )}
              </li>
              <li className="nav-item">
                {type !== "navigation" ? (
                  <ScrollLink
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    to="section-4"
                    duration={500}
                  >
                    <a className="btn btn-sm left-btn" href="#">
                      Containers
                    </a>
                  </ScrollLink>
                ) : (
                  <NavigationLink to="/container/select">
                    <a className="btn btn-sm left-btn" href="#">
                      Containers
                    </a>
                  </NavigationLink>
                )}
              </li>
            </ul>
            {/* </div> */}
          </div>
          <button
            type="button"
            className="btn  left-btn call-btn"
            href="#"
            onClick={callHandler}
          >
            Call Us
          </button>
        </nav>
      </div>
    </>
  );
}
