import React from "react";

import "../../styles/ContainerPage.css";

import AppButton from "../AppButton";

export default function SelectionPage({
  mainHeading = "",
  subHeading = "",
  defaultSelect = "",
  selectOptions = [],
  leftButton,
  rightButton,
  leftButtonClickHandler,
  rightButtonClickHandler,
  optionSelected,
  inputPlaceholder = "Enter...",
  inputEntered,
  imgSrc = "",
}) {
  return (
    <div className="selectPage-container">
      <div className="left-pane">
        <div className="selectPage-main-heading">{mainHeading}</div>
        <div className="field-area">
          <div className="selectPage-label-heading">{subHeading}</div>
          {inputEntered ? (
            <input
              class="input-field"
              placeholder={inputPlaceholder}
              onChange={(event) => {
                inputEntered(event.target.value);
              }}
            />
          ) : (
            <select
              class="form-control form-control-lg select-field"
              onChange={(event) => {
                optionSelected(event.target.value, event.target.selectedIndex);
              }}
            >
              <option value="">{defaultSelect}</option>
              {selectOptions.map((opt) => {
                return <option value={opt}>{opt}</option>;
              })}
            </select>
          )}
        </div>
        <div className="bottom-buttons">
          {leftButton ? (
            <AppButton
              title={leftButton}
              styles={{
                color: "white",
                borderRadius: "5px",
                height: "40px",
                width: "140px",
              }}
              onButtonClick={leftButtonClickHandler}
            />
          ) : null}

          {rightButton ? (
            <AppButton
              title={rightButton}
              bgColor="black"
              styles={{
                color: "#ED392D",
                borderRadius: "5px",
                height: "40px",
                width: "140px",
                border: "1px solid #ED392D",
                marginLeft: "80px",
              }}
              // to={rightButtonTo}
              onButtonClick={rightButtonClickHandler}
            />
          ) : null}
        </div>
      </div>
      <div className="right-pane">
        <img src={imgSrc} className="selectPage-image" />
      </div>
    </div>
  );
}
