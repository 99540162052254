import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
function AppButton({
  title,
  bgColor = "#ED392D",
  styles,
  buttonFor = "",
  to = "",
  onButtonClick = null,
  GAEvent = null,
}) {
  const googleAnalyticsEventTrigger = async () => {
    if (GAEvent) {
      await ReactGA.event({
        category: GAEvent.title,
        action: GAEvent.description,
      });
    }
    if (onButtonClick) onButtonClick();
  };
  if (to)
    return (
      <Link
        className="app-btn"
        style={{
          backgroundColor: bgColor,
          cursor: "pointer",
          ...styles,
        }}
        onClick={() => googleAnalyticsEventTrigger()}
        to={to}
      >
        {title}
      </Link>
    );
  return (
    <div
      className="app-btn"
      style={{
        backgroundColor: bgColor,
        cursor: "pointer",
        ...styles,
      }}
      onClick={() => googleAnalyticsEventTrigger()}
    >
      {title}
    </div>
  );
}

export default AppButton;
