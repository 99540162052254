import React from "react";

export default function AppDetail({ detail, detail2 = "" }) {
  return (
    <div className="hero-detail-container">
      <p className="hero-detail">{detail}</p>
      {detail2 ? <p className="hero-detail">{detail2}</p> : null}
    </div>
  );
}
