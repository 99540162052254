import React from "react";
import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import AppButton from "../components/AppButton";
import "../styles/ComingSoon.css";
import useTopScroll from "../hooks/useTopScroll";

export default function ComingSoon() {
  useTopScroll();
  return (
    <div>
      <AppNavBar />
      <div className="upcoming-container">
        <div className="upcoming-title">Coming Soon</div>
        <AppButton title="Home" to="/" styles={{ marginTop: "30px" }} />
      </div>
      <div></div>
      <AppFooter />
    </div>
  );
}
