import React from "react";
import AppListItem from "./AppListItem";

export default function AppLists({ allItems, ...otherstyles }) {
  return (
    <ul
      className="offer-details offer-detail-list"
      style={{ ...otherstyles.styles }}
    >
      {allItems.map((item) => (
        <AppListItem content={item} />
      ))}
    </ul>
  );
}
