import React, { useEffect, useState } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import ContractUploadSection from "../components/Sections/ContractUploadSection";
import history from "../history";
import SpaceBooking from "../APIs/SpaceBooking";
import Thawani from "../APIs/Thawani";
import { eventBanner } from "../assets/outsourceAssets";
import { eventSpaceImg as eventBannerMobile } from "../assets/outsourceAssets";
import ReactGA from "react-ga";
import config from "../config/key";

export default function EventContractUploadScreen() {
  const [contract, setContract] = useState({ name: "", file: null });
  const [rightBtn, setRightBtn] = useState("Pay Now");
  const [remTime, setRetTime] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("eventBookingId"))
      history.push("/event/bookingDates");
    if (!JSON.parse(localStorage.getItem("eventContractDownloaded")))
      history.push("/event/contractDownload");
    calculateTime();
  }, []);

  useTopScroll();

  const renderBanner = () => {
    if (window.innerWidth < 786) return eventBannerMobile;
    return eventBanner;
  };

  const onContractUpload = async (elem) => {
    setContract({
      name: elem.target.value,
      file: elem.target.files[0],
    });
    await ReactGA.event({
      category: "Event Space contract upload button (Choose File)",
      action: `Button located at ${window.location.href}`,
    });
  };

  const onNext = async () => {
    try {
      if (contract.file) {
        setRightBtn("Uploading...");
        let formData = new FormData();

        formData.append("contract", contract.file);

        let res = await SpaceBooking.post(
          "/contract?bookingId=" + localStorage.getItem("eventBookingId"),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        await ReactGA.event({
          category: "Event Space go to payment Screen Button (Pay now)",
          action: `Button located at ${window.location.href}`,
        });
        if (res.status === 202) {
          localStorage.removeItem("eventContractDownloaded");
          history.push("/event/payment");
        }
        setRightBtn("Pay Now");
      } else {
        alert("Please Upload Contract First");
      }
    } catch (e) {
      console.log(e);
      alert("Oops, An Error Occured While Uploading");
      setRightBtn("Pay Now");
    }
  };

  const validatePayment = async () => {
    try {
      const res = await SpaceBooking.get(
        "/sessionId?bookingId=" + localStorage.getItem("eventBookingId")
      );
      if (res.data.sessionId) {
        const res2 = await Thawani.get(
          `/api/v1/checkout/session/${res.data.sessionId}`,
          {
            headers: {
              "thawani-api-key": config.THAWANI_SECRET_KEY,
            },
          }
        );
        if (res2.data.success === true) {
          if (res2.data.data.payment_status !== "unpaid") {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  function msToTime(duration) {
    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
  }

  const calculateTime = async () => {
    const mils =
      new Date(localStorage.getItem("eventBookingTime")).setHours(
        new Date().getHours() + 5
      ) - new Date();
    const res = await validatePayment();
    if (res) {
      localStorage.removeItem("eventContractDownloaded");
      history.push("/event/confirmation");
    }
    if (mils <= 0) {
      await SpaceBooking.delete(
        "/delete?bookingId=" + localStorage.getItem("eventBookingId")
      );
      setRetTime("Expired, Booking Cancelled");
    }
    setRetTime(msToTime(mils));
  };

  return (
    <div>
      <AppNavBar />
      <ContractUploadSection
        mainHeading="The Contract"
        rightButton={rightBtn}
        // to="/event/payment"
        contract={contract}
        contractChangeHandler={onContractUpload}
        onNext={onNext}
        bannerSrc={renderBanner()}
        remainingTime={remTime}
      />
      <AppFooter />
    </div>
  );
}
