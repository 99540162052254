import React, { useEffect, useState } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import ContractUploadSection from "../components/Sections/ContractUploadSection";
import history from "../history";
import ScreenBooking from "../APIs/ScreenBooking";
import Thawani from "../APIs/Thawani";
import { screenBanner } from "../assets/outsourceAssets";
import { ScreenImg as screenBannerMobile } from "../assets/outsourceAssets";
import ReactGA from "react-ga";
import config from "../config/key";

export default function ScreenContractUploadScreen() {
  const [contract, setContract] = useState({ name: "", file: null });
  const [video, setVideo] = useState({ name: "", file: null });
  const [rightBtn, setRightBtn] = useState("Pay Now");
  const [remTime, setRetTime] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("screenBookingId"))
      history.push("/screen/bookingDates");
    if (!JSON.parse(localStorage.getItem("screenContractDownloaded")))
      history.push("/screen/contractDownload");
    calculateTime();
  }, []);

  useTopScroll();

  const renderBanner = () => {
    if (window.innerWidth < 786) return screenBannerMobile;
    return screenBanner;
  };

  const onContractUpload = async (elem) => {
    setContract({
      name: elem.target.value,
      file: elem.target.files[0],
    });
    await ReactGA.event({
      category: "Screen contract upload button (Choose File)",
      action: `Button located at ${window.location.href}`,
    });
  };

  const onVideoUpload = async (elem) => {
    setVideo({
      name: elem.target.value,
      file: elem.target.files[0],
    });
    await ReactGA.event({
      category: "Screen media upload button (Choose File)",
      action: `Button located at ${window.location.href}`,
    });
  };

  const onNext = async () => {
    try {
      if (contract.file && video.file) {
        setRightBtn("Uploading...");
        let formData = new FormData();
        formData.append("contract", contract.file);
        let res = await ScreenBooking.post(
          "/contract?bookingId=" + localStorage.getItem("screenBookingId"),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.status === 202) {
          let formData2 = new FormData();

          formData2.append("video", video.file);

          let res2 = await ScreenBooking.post(
            "/video?bookingId=" + localStorage.getItem("screenBookingId"),
            formData2,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          ReactGA.event({
            category: "Screen go to payment Screen Button (Pay Now)",
            action: `Button located at ${window.location.href}`,
          });

          if (res2.status === 202) {
            localStorage.removeItem("screenContractDownloaded");
            history.push("/screen/payment");
          }
        }
        setRightBtn("Pay Now");
      } else if (!contract.file && video.file) {
        alert("Please Upload Contract");
      } else if (contract.file && !video.file) {
        alert("Please Upload Video");
      } else {
        alert("Please Upload Both Files");
      }
    } catch (e) {
      console.log(e);
      alert("Oops, An Error Occured While Uploading");
      setRightBtn("Pay Now");
    }
  };

  const validatePayment = async () => {
    try {
      const res = await ScreenBooking.get(
        "/sessionId?bookingId=" + localStorage.getItem("screenBookingId")
      );
      if (res.data.sessionId) {
        const res2 = await Thawani.get(
          `/api/v1/checkout/session/${res.data.sessionId}`,
          {
            headers: {
              "thawani-api-key": config.THAWANI_SECRET_KEY,
            },
          }
        );
        if (res2.data.success === true) {
          if (res2.data.data.payment_status !== "unpaid") {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  function msToTime(duration) {
    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
  }

  const calculateTime = async () => {
    const mils =
      new Date(localStorage.getItem("screenBookingTime")).setHours(
        new Date().getHours() + 5
      ) - new Date();
    const res = await validatePayment();
    if (res) {
      localStorage.removeItem("screenContractDownloaded");
      history.push("/screen/confirmation");
    }
    if (mils <= 0) {
      await ScreenBooking.delete(
        "/delete?bookingId=" + localStorage.getItem("screenBookingId")
      );
      setRetTime("Expired, Booking Cancelled");
    }
    setRetTime(msToTime(mils));
  };

  return (
    <div>
      <AppNavBar />
      <ContractUploadSection
        mainHeading="The Contract"
        leftButton="Upload Contract"
        rightButton={rightBtn}
        // to="/screen/payment"
        uploadType="double"
        video={video}
        videoChangeHandler={onVideoUpload}
        contract={contract}
        contractChangeHandler={onContractUpload}
        onNext={onNext}
        bannerSrc={renderBanner()}
        payNowGAEvent={{
          title: "Screen go to payment Screen Button (Pay Now)",
          description: `Button located at ${window.location.href}`,
        }}
        remainingTime={remTime}
      />
      <AppFooter />
    </div>
  );
}
