import React, { useEffect } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import BookingConfirmationSection from "../components/Sections/BookingConfirmationSection";
import { containerBanner } from "../assets/outsourceAssets";
import { containerImg as containerBannerMobile } from "../assets/outsourceAssets";

export default function ContainerBookingConfirmationScreen() {
  useTopScroll();
  useEffect(() => {
    localStorage.removeItem("leadBookingId");
  }, []);

  const renderBanner = () => {
    if (window.innerWidth < 786) return containerBannerMobile;
    return containerBanner;
  };

  return (
    <div>
      <AppNavBar />
      <BookingConfirmationSection
        mainHeading={"Booking Confirmation"}
        confirmationDetails={
          "Thank you for customizing your container. Thy Yard's representative will be in contact with you to further discuss your requirements."
        }
        mainButton={"Go to Home"}
        to="/"
        homeGAEvent={{
          title: "Container confirmation go to home Button",
          description: `Button located at ${window.location.href}`,
        }}
        bannerSrc={renderBanner()}
      />
      <AppFooter />
    </div>
  );
}
