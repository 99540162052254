import React from "react";
import "../../styles/BookingConfirmation.css";

import AppButton from "../AppButton";
import AppInfoImages from "../AppInfoImages";
import AppInfoHeading from "../AppInfoHeading";

export default function BookingConfirmationSection({
  mainHeading,
  confirmationDetails,
  mainButton,
  to = "",
  bannerSrc = "",
  homeGAEvent = null,
}) {
  return (
    <div className="confirmation-container">
      <AppInfoImages source={bannerSrc} />
      <AppInfoHeading mainHeading={mainHeading} />
      <p className="info-text">{confirmationDetails}</p>
      <AppButton
        title={mainButton}
        styles={{
          fontSize: "12px",
          fontWeight: 500,
          height: "40px",
          width: "140px",
          marginBottom: "100px",
        }}
        to={to}
        GAEvent={homeGAEvent}
      />
    </div>
  );
}
