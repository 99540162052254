import React from "react";
import AppButton from "../AppButton";
import AppTitle from "../AppTitle";
import { connectUsLogo } from "../../assets/outsourceAssets";

export default function ConnectUs() {
  return (
    <div className="contact-section connect-us">
      <img src={connectUsLogo} className="connect-us-logo" />
      <div>
        <AppTitle title="Lets Do It Together :)" />
      </div>
      <div
        className="connect-segment"
        style={{ marginTop: "40px", marginBottom: "30px" }}
      >
        <input
          style={{ width: "472px", height: "64px" }}
          type="text"
          class="connect-input form-control"
          placeholder="email"
        ></input>
      </div>
      <AppButton
        title="Lets Connect"
        GAEvent={{
          title: "Lets Connect Btn",
          description: "Button on Home Screen to connect using email",
        }}
      />
    </div>
  );
}
