import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../styles/ScreenBooking.css";

import AppInfoImages from "../AppInfoImages";
import AppInfoHeading from "../AppInfoHeading";
import AppButton from "../AppButton";
import AppLoader from "../AppLoader";

const ScreenBooking = ({
  mainHeading,
  mainButton,
  // to = "",
  disabledDatesArray = [],
  onDateSelection,
  onClickNext,
  showCalender = false,
  bannerSrc = "",
  nextBtnGAEvent = null,
}) => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const onDateSelect = (date) => {
    onDateSelection(date.selection);
    setRange([date.selection]);
  };

  return (
    <div className="calender-container">
      <AppInfoImages source={bannerSrc} />
      <AppInfoHeading mainHeading={mainHeading} />
      <span
        style={{
          color: "#ED392D",
          fontSize: "20px",
          marginBottom: "-20px",
          marginTop: "35px",
        }}
      >
        Pick Your Dates
      </span>
      <div style={{ marginTop: "25px" }}>
        {showCalender ? (
          <DateRangePicker
            ranges={range}
            onChange={onDateSelect}
            rangeColors={["#ed392d"]}
            months={2}
            direction="horizontal"
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            showPreview={false}
            disabledDates={disabledDatesArray}
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
          />
        ) : (
          <AppLoader />
        )}
      </div>
      <AppButton
        title={mainButton}
        styles={{
          fontSize: "12px",
          fontWeight: 500,
          height: "40px",
          width: "140px",
          marginBottom: "100px",
          color: "white",
          marginTop: "30px",
        }}
        // to={to}
        onButtonClick={onClickNext}
        GAEvent={nextBtnGAEvent}
      />
    </div>
  );
};
export default ScreenBooking;
