import React from "react";
import AppImageLogo from "../AppImageLogo";
import history from "../../history";
import aboutUs from "../../assets/aboutThyyard.pdf";

export default function HomeFooter() {
  const aboutUsDowload = () => {
    const link = document.createElement("a");
    link.setAttribute("download", "aboutThyyard.pdf");
    link.setAttribute("href", aboutUs);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <footer className="home-footer-container">
      <div className="upper-part">
        <a className="footer-link" onClick={() => history.push("/upcoming")}>
          Food
        </a>
        <a className="footer-link" onClick={() => history.push("/event/info")}>
          Events
        </a>
        <a className="footer-link" onClick={() => history.push("/screen/info")}>
          Screen
        </a>
        <span className="footer-heading main-footer-heading">Thy yard kit</span>
        <a className="footer-link" onClick={aboutUsDowload}>
          About
        </a>
        <a className="footer-link" onClick={() => history.push("/upcoming")}>
          News
        </a>
        <a
          className="footer-link"
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=ThyYardKit@gmail.com"
          target="_black"
        >
          Email Us
        </a>
      </div>
      <div className="social-links-part">
        <AppImageLogo
          logo="fab fa-facebook-f"
          link="https://www.facebook.com/Thy_Yard-495354687635859/"
        />
        <AppImageLogo
          logo="fab fa-instagram"
          link="https://www.instagram.com/thy_yard.om/"
        />
        <AppImageLogo
          logo="fab fa-snapchat-ghost"
          link="https://www.snapchat.com/add/thy_yard"
        />
        <AppImageLogo
          logo="fab fa-whatsapp"
          link="https://wa.me/message/VQNTLUNKHUE5L1"
        />
      </div>
      <div className="lower-part">
        <p>© 2020— 2021</p>
        <p>Privacy — Terms</p>
      </div>
    </footer>
  );
}
