import React from "react";
import "../../styles/CompanyInfo.css";

import AppButton from "../AppButton";
import AppLoader from "../AppLoader";

export default function CompanyInfoSection({
  _id = "",
  companyDetail = "Company Description",
  servicesList = [],
  companyName = "Company Name",
  companyLogo = "",
  onCustomizeContainer,
}) {
  const renderServicesList = () => {
    return servicesList.map((list) => {
      return <li className="container-info-srvc-list">{list}</li>;
    });
  };

  return (
    <div className="container-info-container">
      {_id ? (
        <>
          <img alt="info-logo" className="info-logo" src={companyLogo} />
          <span className="info-heading">{companyName}</span>
          <div className="container-info-details">
            <p className="container-info-text">{companyDetail}</p>
            <div className="container-info-services">
              <div className="container-info-srvc-head">Our Services:</div>
              <ul>{renderServicesList()}</ul>
            </div>
          </div>

          <AppButton
            title="Customize Now"
            styles={{
              fontSize: "12px",
              fontWeight: 500,
              height: "40px",
              width: "140px",
              marginBottom: "100px",
            }}
            // to="/container/page1"
            onButtonClick={onCustomizeContainer}
            GAEvent={{
              title: `${companyName} company customize now btn`,
              description: `Button on container/info/${_id} Screen to navigate towards ${
                "/container/contact/" + _id + "/" + companyName
              }`,
            }}
          />
        </>
      ) : (
        <AppLoader />
      )}
    </div>
  );
}
