import React from 'react';
import { Element } from 'react-scroll';

import AppButton from '../AppButton';
import { offerImage } from '../../assets/outsourceAssets';
import AppDetail from '../AppDetail';
import AppTitle from '../AppTitle';
import AppVideo from '../AppImageVideo';
import AppLists from '../AppLists';
import QRCODE from '../../assets/QR.png';

const allItems = [
  'Order directly from your car with QR code',
  'Deliver the food directly at home',
  'Wide variety of options'
];

function OfferSection1() {
  return (
    <Element name="section-1">
      <div className="right-img hero-section section-1">
        <div className="hero-left">
          <AppTitle title="Are you Hungry? Order Now" fontWeight={700} />{' '}
          <AppDetail detail="Thy Yard introduces set of delicious restaurants/Cafes with amazing food and drinks." />
          <AppLists allItems={allItems} />
          <div className="section-1-dual-btns">
            {/* <AppButton
              title="Order Now"
              styles={{
                width: "9.7vw",
                height: "45px",
                borderRadius: "5px",
                marginRight: "15px",
              }}
            />
            <AppButton
              title="Download Now"
              styles={{ width: "9.7vw", height: "45px", borderRadius: "5px" }}
            /> */}
            <img
              src={QRCODE}
              alt="QR CODE"
              style={{
                height: '270px',
                width: '270px',
                borderRadius: '5px',
                overflow: 'hidden'
              }}
            />
          </div>{' '}
        </div>{' '}
        <div className="hero-right">
          <AppVideo
            otherStyles={{ backgroundSize: 'cover' }}
            image={offerImage}
          />
        </div>{' '}
      </div>
    </Element>
  );
}
export default OfferSection1;

/*<div id="offer-1-section" className="offer-section">
      <div className="offer-left">
        <div className="offer-detail-container">
          <p className="offer-details offer-detail-title">
            Are you Hungry? Order Now
          </p>
          <p className="offer-details offer-detail-text">
            Thy Yard introduces set of delicious restaurants/Cafes with amazing
            food and drinks.{" "}
          </p>
          <ul className="offer-details offer-detail-list">
            <li className="offer-detail-listItem">
              Order directly from your car with QR code
            </li>
            <li className="offer-detail-listItem">
              Deliver the food directly at home
            </li>
            <li className="offer-detail-listItem">Wide variety of options</li>
          </ul>
        </div>
        <AppButton title="Order Now" />
      </div>
      <div className="offer-image-container">
        <img className="offer-image" src={offerImage} />
      </div>
    </div> */
