import React from "react";
import "../../styles/InfoScreen.css";

import AppTitle from "../AppTitle";
import AppLists from "../AppLists";
import AppButton from "../AppButton";
import AppInfoImages from "../AppInfoImages";
import AppInfoHeading from "../AppInfoHeading";

export default function CompanyInfoSection({
  rightAllItems,
  mainHeading,
  leftDetails,
  leftButton,
  rightButton,
  rightHeading,
  to = "",
  bannerSrc = "",
  leftBtnGAEvent = null,
  onRightClick = null,
}) {
  return (
    <div className="info-container">
      <AppInfoImages source={bannerSrc} />
      <AppInfoHeading mainHeading={mainHeading} />
      <div className="info-details-segment">
        <div className="info-details">
          <p>{leftDetails}</p>
        </div>
        <div className="info-details">
          <AppTitle
            title={rightHeading}
            fontWeight={700}
            lineHeight="41.49px"
            fontSize="18px"
          />
          <AppLists allItems={rightAllItems} styles={{ fontSize: "18px" }} />
        </div>
      </div>
      <div className="info-left-details-buttons">
        <AppButton
          title={leftButton}
          styles={{
            height: "40px",
            width: "140px",
            fontSize: "12px",
            marginRight: "3.5vw",
          }}
          to={to}
          GAEvent={leftBtnGAEvent}
        />
        <AppButton
          title={rightButton}
          bgColor="black"
          styles={{
            height: "40px",
            width: "140px",
            border: "1px solid #ED392D",
            fontSize: "12px",
          }}
          onButtonClick={onRightClick}
        />
      </div>
    </div>
  );
}
