import React from "react";
import "../../styles/ContractPage.css";

import AppContractLists from "../AppContractLists";
import AppButton from "../AppButton";
import AppTimer from "../AppTimer";
import AppTitle from "../AppTitle";
import AppInfoImages from "../AppInfoImages";
import AppInfoHeading from "../AppInfoHeading";

export default function CompanyInfoSection({
  listHeading,
  allItems,
  mainHeading,
  leftButton,
  rightButton,
  // to = "",
  onLeftButtonClick,
  onRightButtonClick,
  bannerSrc = "",
  downloadContractGAEvent = null,
  nextPageGAEvent = null,
}) {
  return (
    <div className="page-container">
      <AppInfoImages source={bannerSrc} />
      <AppInfoHeading mainHeading={mainHeading} />
      <div className="details-section">
        {/* <AppTimer timeRemaining="04:59" /> */}
        <AppContractLists
          listHeading={listHeading}
          allItems={allItems}
          styles={{
            fontSize: "18px",
            color: "white",
            lineHeight: "18.8px",
            fontWeight: "400",
          }}
        />
        <AppTitle
          title="Download the Contract by clicking the button below "
          styles={{
            color: "white",
            marginTop: "20px",
            marginBottom: "20px",
            fontWeight: "700",
          }}
        />
        <div className="page-left-details-buttons">
          <AppButton
            title={leftButton}
            styles={{
              height: "40px",
              width: "140px",
              fontSize: "12px",
              marginRight: "1.5vw",
            }}
            onButtonClick={onLeftButtonClick}
            GAEvent={downloadContractGAEvent}
          />
          <AppButton
            title={rightButton}
            bgColor="black"
            styles={{
              height: "40px",
              width: "140px",
              border: "1px solid #ED392D",
              fontSize: "12px",
            }}
            onButtonClick={onRightButtonClick}
            GAEvent={nextPageGAEvent}
          />
        </div>
      </div>
    </div>
  );
}
