import React, { useEffect, useState } from "react";
import "../styles/ContractForm.css";
import moment from "moment";
import SpaceBooking from "../APIs/SpaceBooking";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import AppInfoImages from "../components/AppInfoImages";
import AppInfoHeading from "../components/AppInfoHeading";
import AppTitle from "../components/AppTitle";
import AppInput from "../components/AppInput";
import AppButton from "../components/AppButton";
import { eventBanner } from "../assets/outsourceAssets";
import { eventSpaceImg as eventBannerMobile } from "../assets/outsourceAssets";
import history from "../history";

export default function ScreenEventContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedDateText, setSelectedDateText] = useState("");
  const [inputErrors, setInputErrors] = useState({
    name: null,
    email: null,
    contact: null,
  });

  useEffect(() => {
    const dates = localStorage.getItem("spaceBookingDates");
    if (!dates) history.push("/event/bookingDates");

    const selectedDates = JSON.parse(dates);

    if (selectedDates) {
      setSelectedDateText(
        `${moment(new Date(selectedDates.startDate)).format(
          "Do MMM YYYY"
        )} - ${moment(new Date(selectedDates.endDate)).format(
          "Do MMM YYYY"
        )} available`
      );
      setSelectedDate(selectedDates);
    }
  }, []);

  useTopScroll();

  const renderBanner = () => {
    if (window.innerWidth < 786) return eventBannerMobile;
    return eventBanner;
  };

  const onFormSubmit = () => {
    if (name.length === 0)
      return setInputErrors({ name: "Name is required field" });
    if (name.length < 3)
      return setInputErrors({
        name: "Name length must be minimun 3 characters",
      });

    //email error check
    if (email.length === 0)
      return setInputErrors({ email: "Email is required field" });
    //contact error checks
    if (contact.length === 0)
      return setInputErrors({ contact: "Contract is required field" });
    if (contact.length < 10)
      return setInputErrors({
        contact: "Contact length must be minimun 10 letters",
      });

    SpaceBooking.post("/add", {
      name,
      email,
      contact,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
    })
      .then((res) => {
        localStorage.setItem("eventBookingId", res.data.booking._id);
        localStorage.setItem(
          "eventUserInfo",
          JSON.stringify({
            name,
            email,
            contact,
          })
        );
        localStorage.removeItem("spaceBookingDates");
        history.push("/event/contractDownload");
      })
      .catch((e) => {
        if (Array.isArray(e.response.data.error)) {
          setInputErrors({ email: e.response.data.error[0] });
        }
      });
  };

  if (selectedDate.startDate && selectedDate.endDate)
    return (
      <div>
        <AppNavBar />
        <div className="contract-container">
          <AppInfoImages source={renderBanner()} />
          {selectedDateText ? (
            <AppInfoHeading mainHeading={selectedDateText} />
          ) : (
            <></>
          )}
          <div className="form-segment">
            <AppTitle
              title="Your choice of dates are avaialble to book your space. Please tell us your contact information and click next to complete your booking. We will hold your slot for next 5 hours in order to let you complete the booking process"
              styles={{
                color: "white",
                marginTop: "20px",
                marginBottom: "20px",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            />
            <AppInput
              type="text"
              label="Name"
              placeholder="John Doe"
              inputValue={name}
              onInputChange={(elem) => {
                setName(elem.target.value);
                setInputErrors({ name: null });
              }}
              error={inputErrors.name}
            />
            <AppInput
              type="email"
              label="Email"
              placeholder="abc@abc.com"
              inputValue={email}
              onInputChange={(elem) => {
                setEmail(elem.target.value);
                setInputErrors({ email: null });
              }}
              error={inputErrors.email}
            />
            <AppInput
              type="tel"
              label="Contact Number"
              placeholder="+974 12345678"
              inputValue={contact}
              onInputChange={(elem) => {
                setContact(elem.target.value);
                setInputErrors({ contact: null });
              }}
              error={inputErrors.contact}
            />
            <AppButton
              title="Next"
              styles={{
                marginTop: "4rem",
                height: "40px",
                width: "140px",
                fontSize: "12px",
              }}
              // to="/event/contractDownload"
              onButtonClick={onFormSubmit}
              GAEvent={{
                title: "Event Space Info Form Button (Next)",
                description: `Button located at ${window.location.href}`,
              }}
            />
          </div>
        </div>
        <AppFooter />
      </div>
    );
  return <div></div>;
}
