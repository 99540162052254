import React, { useEffect } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import BookingConfirmationSection from "../components/Sections/BookingConfirmationSection";
import { eventBanner } from "../assets/outsourceAssets";
import { eventSpaceImg as eventBannerMobile } from "../assets/outsourceAssets";

export default function EventBookingConfirmationScreen() {
  useTopScroll();

  useEffect(() => {
    localStorage.removeItem("eventBookingId");
    localStorage.removeItem("eventUserInfo");
  }, []);

  const renderBanner = () => {
    if (window.innerWidth < 786) return eventBannerMobile;
    return eventBanner;
  };

  return (
    <div>
      <AppNavBar />
      <BookingConfirmationSection
        mainHeading={"Booking Confirmation"}
        confirmationDetails={
          "Thank you for booking Thy Yard for your event. We have sent you a confimation email and a reciept of your payment. Thy Yard’s representative will be in contact with you to further discuss your requirements. "
        }
        mainButton={"Go to Home"}
        to="/"
        bannerSrc={renderBanner()}
        homeGAEvent={{
          title: "Event Space confirmation go to home Button (Go to home)",
          description: `Button located at ${window.location.href}`,
        }}
      />
      <AppFooter />
    </div>
  );
}
