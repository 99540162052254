import React from "react";

import AppDetail from "../AppDetail";
import AppTitle from "../AppTitle";
import AppVideo from "../AppImageVideo";
import HeroSectionCircles from "../HomeCircles/HeroSectionCircles";
import { heroVideo } from "../../assets/outsourceAssets";

function HeroSection() {
  return (
    <div className="right-img hero-section section-hero">
      <HeroSectionCircles />
      <div className="hero-left">
        <AppTitle fontSize="56px" title="Thy Yard Kit" color="#ED392D" />
        <AppDetail
          detail="Thy Yard project facilitate community with divine leisure and open doors
        to fresh concept of culinary experiences."
          detail2="Thy Yard Kit gives you access
        to the service provided and to ease the process of booking and buying
        containers."
        />
        {/* <AppButton title="Book Now" /> */}
      </div>
      <div className="hero-right">
        <AppVideo video={heroVideo} />
      </div>
    </div>
  );
}
export default HeroSection;
