import React, { useEffect, useState } from "react";
import "../styles/ContractForm.css";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import AppInfoImages from "../components/AppInfoImages";
import AppInfoHeading from "../components/AppInfoHeading";
import AppTitle from "../components/AppTitle";
import AppInput from "../components/AppInput";
import AppButton from "../components/AppButton";
import { containerBanner } from "../assets/outsourceAssets";
import { containerImg as containerBannerMobile } from "../assets/outsourceAssets";
import history from "../history";
import Lead from "../APIs/Lead";

export default function ScreenEventContactForm({ match }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [inputErrors, setInputErrors] = useState({
    name: "",
    email: "",
    contact: "",
  });

  useEffect(() => {}, []);

  useTopScroll();

  const renderBanner = () => {
    if (window.innerWidth < 786) return containerBannerMobile;
    return containerBanner;
  };

  const onFormSubmit = () => {
    if (name.length === 0)
      return setInputErrors({ name: "Name is required field" });
    if (name.length < 3)
      return setInputErrors({
        name: "Name length must be minimun 3 characters",
      });

    //email error check
    if (email.length === 0)
      return setInputErrors({ email: "Email is required field" });
    //contact error checks
    if (contact.length === 0)
      return setInputErrors({ contact: "Contract is required field" });
    if (contact.length < 10)
      return setInputErrors({
        contact: "Contact length must be minimun 10 letters",
      });
    Lead.post("/add", {
      name,
      email,
      contact,
      companyId: match.params.companyId,
      companyName: match.params.companyName,
    })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("leadBookingId", res.data.lead._id);
        history.push(
          "/container/questions/" +
            match.params.companyId +
            "/" +
            match.params.companyName
        );
      })
      .catch((e) => {
        if (Array.isArray(e.response.data.error)) {
          setInputErrors({ email: e.response.data.error[0] });
        }
      });
  };

  return (
    <div>
      <AppNavBar />
      <div className="contract-container">
        <AppInfoImages source={renderBanner()} />
        <AppInfoHeading mainHeading={"Contact Information"} />
        <div className="form-segment">
          <AppTitle
            title="Before we ask questions to customize your container, we require your contact information. The purpose of this information is to contact you to make your container booking experience delightful. "
            styles={{
              color: "white",
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "18px",
              lineHeight: "28px",
            }}
          />
          <AppInput
            type="text"
            label="Name"
            placeholder="John Doe"
            inputValue={name}
            onInputChange={(elem) => {
              setName(elem.target.value);
              setInputErrors({ name: null });
            }}
            error={inputErrors.name}
          />
          <AppInput
            type="email"
            label="Email"
            placeholder="abc@abc.com"
            inputValue={email}
            onInputChange={(elem) => {
              setEmail(elem.target.value);
              setInputErrors({ email: null });
            }}
            error={inputErrors.email}
          />
          <AppInput
            type="tel"
            label="Contact Number"
            placeholder="+974 12345678"
            inputValue={contact}
            onInputChange={(elem) => {
              setContact(elem.target.value);
              setInputErrors({ contact: null });
            }}
            error={inputErrors.contact}
          />
          <AppButton
            title="Next"
            styles={{
              marginTop: "4rem",
              height: "40px",
              width: "140px",
              fontSize: "12px",
            }}
            // to="/event/contractDownload"
            onButtonClick={onFormSubmit}
            GAEvent={{
              title: "Container Contact Form Next Btn",
              description: `Button on ${window.location.href} Screen`,
            }}
          />
        </div>
      </div>
      <AppFooter />
    </div>
  );
}
