import React, { useState, useEffect } from "react";
import useTopScroll from "../hooks/useTopScroll";
import SpaceBooking from "../APIs/SpaceBooking";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import CalenderSection from "../components/Sections/CalenderSection";
import { eventBanner } from "../assets/outsourceAssets";
import { eventSpaceImg as eventBannerMobile } from "../assets/outsourceAssets";
import history from "../history";

const EventCalenderScreen = () => {
  const [showCalender, setShowCalender] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });

  Date.prototype.addDays = function (days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  };

  function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(currentDate);
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  const fetchBookings = () => {
    SpaceBooking.get("all")
      .then((res) => {
        let disabledDatesArray = [];
        res.data.bookings.forEach((booking) => {
          disabledDatesArray = disabledDatesArray.concat(
            getDates(new Date(booking.startDate), new Date(booking.endDate))
          );
        });
        setShowCalender(true);
        setDisabledDates(disabledDatesArray);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  useTopScroll();

  const renderBanner = () => {
    if (window.innerWidth < 786) return eventBannerMobile;
    return eventBanner;
  };

  const onDateSelect = (date) => {
    const startDate = new Date(date.startDate);
    startDate.setHours("00");
    startDate.setMinutes("01");
    const endDate = new Date(date.endDate);
    endDate.setHours("23");
    endDate.setMinutes("59");
    setSelectedDate({ startDate, endDate });
  };

  const onDateSubmit = () => {
    if (selectedDate.startDate && selectedDate.endDate) {
      const dates = JSON.stringify(selectedDate);
      localStorage.setItem("spaceBookingDates", dates);
      history.push("/event/contract");
    } else {
      alert("Please Select Booking Dates");
    }
  };

  return (
    <div>
      <AppNavBar />
      <CalenderSection
        mainHeading="Check the dates for Thy Yard’s Space"
        mainButton="Next"
        disabledDatesArray={disabledDates}
        onDateSelection={onDateSelect}
        onClickNext={onDateSubmit}
        showCalender={showCalender}
        bannerSrc={renderBanner()}
        nextBtnGAEvent={{
          title: "Event Space Date Selection Button (Next)",
          description: `Button located at ${window.location.href}`,
        }}
      />
      <AppFooter />
    </div>
  );
};
export default EventCalenderScreen;
