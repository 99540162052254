import React, { useEffect } from "react";

import useTopScroll from "../hooks/useTopScroll";

import AppNavBar from "../components/Sections/AppNavBar";
import AppFooter from "../components/Sections/AppFooter";
import BookingConfirmationSection from "../components/Sections/BookingConfirmationSection";
import { screenBanner } from "../assets/outsourceAssets";
import { ScreenImg as screenBannerMobile } from "../assets/outsourceAssets";

export default function ScreenBookingConfirmationScreen() {
  useTopScroll();

  useEffect(() => {
    localStorage.removeItem("screenBookingId");
    localStorage.removeItem("screenUserInfo");
  }, []);

  const renderBanner = () => {
    if (window.innerWidth < 786) return screenBannerMobile;
    return screenBanner;
  };

  return (
    <div>
      <AppNavBar />
      <BookingConfirmationSection
        mainHeading={"Booking Confirmation"}
        confirmationDetails={
          "Thank you for booking Thy Yard’s Screen for your advertisments. We have sent you a confimation email and a reciept of your payment. Thy Yard’s representative will be in contact with you to further discuss your requirements. "
        }
        mainButton={"Go to Home"}
        bannerSrc={renderBanner()}
        to="/"
        homeGAEvent={{
          title: "Screen confirmation go to home Button (Go to home)",
          description: `Button located at ${window.location.href}`,
        }}
      />
      <AppFooter />
    </div>
  );
}
