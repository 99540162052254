import React from "react";

export default function AppLists({ listHeading, allItems, ...otherstyles }) {
  return (
    <div className="app-contract-list-container" style={otherstyles.styles}>
      <p>{listHeading}</p>
      <ol
        className="offer-details offer-detail-list"
        style={{ ...otherstyles.styles }}
      >
        {allItems.map((item) => (
          <li className="offer-detail-listItem">{item}</li>
        ))}
      </ol>
    </div>
  );
}
