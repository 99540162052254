import React from "react";
import BackCircle from "../BackCircle";

export default function HeroSectionCircles() {
  return (
    <>
      <BackCircle
        style={{
          backgroundColor: "#ED392D",
          // height: "160px",
          height: "104.77px",
          width: "104.77px",
          right: "58.4vw",
          // top: "86.5px",
          top: "22.6px",
          zIndex: 1,
        }}
        className="big-red-circle"
      />
      <BackCircle
        style={{
          backgroundColor: "#ED892D",
          // height: "36px",
          height: "23.57px",
          width: "23.57px",
          left: "41.4vw",
          // top: "333px",
          top: "173.43px",
          zIndex: 1,
        }}
        className="medium-orange-circle"
      />
      <BackCircle
        style={{
          backgroundColor: "#4E4957",
          height: "8px",
          width: "8px",
          left: "69vw",
          top: "45px",
          zIndex: 1,
        }}
      />
      <BackCircle
        style={{
          backgroundColor: "#ED392D",
          height: "14px",
          width: "14px",
          left: "96.5vw",
          top: "42px",
          zIndex: 1,
        }}
        className="tiny-red-circle"
      />
      <BackCircle
        style={{
          backgroundColor: "#ED892D",
          height: "14px",
          width: "14px",
          left: "90.5vw",
          top: "633px",
          zIndex: 1,
        }}
      />
    </>
  );
}
