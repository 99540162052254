import React from "react";

export default function AppTitle({ title, fontSize = "25px", ...otherStyles }) {
  return (
    <div className="hero-title-container">
      <h1
        className="hero-title"
        style={{ fontSize: fontSize, ...otherStyles.styles }}
      >
        {title}
      </h1>
    </div>
  );
}
